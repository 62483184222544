import React from "react";
import { graphql } from "gatsby";
import HomePage from "src/templates/homepage";

export const query = graphql`
	query HomepageQuery {
		page: sanityHomepage(_id: { regex: "/(drafts.|)homepage/" }) {
			content: _rawContent(resolveReferences: { maxDepth: 8 })
		}
	}
`;

const IndexPage = (props: any) => {
	const { location } = props;
	const content = props.data.page.content;
	return <HomePage location={location} data={content} />;
};

export default IndexPage;
