import React from "react";
import cx from "classnames";
import { Link } from "src/components/link";
import { ReactComponent as LinkArrow } from "src/images/svg/ArrowRight.svg";

import * as styles from "./arrow-link.module.css";

export const ArrowLink = ({
	type,
	link,
	copy,
	href,
	className,
}: {
	type?: "internal" | "external";
	link?: any;
	copy?: string;
	href?: string;
	className?: string;
}) => {
	return (
		<Link
			type={type || link.linkType}
			link={link}
			href={href}
			className={cx(styles.link, className)}
		>
			<span className="label">
				<span className="underline-on-hover">{copy || link.linkCopy}</span>
				<LinkArrow className={styles.arrow} />
			</span>
		</Link>
	);
};
